.bk-header{
    background-image:url('../image/mountain-landscape-ge17087476_640.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#title{
    color:rgba(255, 255, 255, 0.945);
    text-shadow:2px 2px rgb(243, 8, 153);
    font-family:Petit Formal Script;
}
.bk-footer{
    background-color: rgba(235, 235, 235, 0.945);
}
.bk{
    background-image:url('../image/texture.png');
    background-position: center;
    background-repeat:repeat;
    background-size: cover; 
}
h4{
    font-family:Limelight ;
    padding: 5rem;
    background-image: url('../image/desktop-g200b7c269_1280.jpg');
    background-position: center;
    background-repeat:no-repeat;
    background-size: cover; 
}
.bd{
    border: 5px double rgb(190 255 120);
}
